import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../components/Header';
import { getToken, getQueueList, checkRefreshToken } from '../Functions.js';
import WallboardBlock from '../components/WallboardBlock';

class List extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          queues:[]
        }
        this.queuelist = [
            "abebe75c-18bf-4c9b-9210-d3a7c1331acd", // Zorg
            "8fb0af2d-28a9-4700-861c-05c11f7fa42b", // Overheid
            "83a8511d-14a5-4451-9103-86f43d0c7f4f", // Overige
            "7635338e-e7b7-4372-a3e6-344d114bfcac", // HPS
            // "4200dbb7-fc56-4a5c-9468-87db4f4fd8f8", // Jongens van beheer
            "79c8bdd5-4ada-4a59-a0fd-178cdc97bb03", // SLTN
        ];
      }

      waitingQueueMax(data){
        var getHours = data ? data.longestWaitingConversationDuration.split(":")[0] : '';
        var getMinutes = data ? data.longestWaitingConversationDuration.split(":")[1] : '';
        var getSeconds = data ? data.longestWaitingConversationDuration.split(":")[2] : '';
        return getHours + ':' + getMinutes + ':' + getSeconds.toString()[0] + '' + getSeconds.toString()[1];
      }

    componentDidMount(){

        //clear sessions from other pages
        sessionStorage.clear();

        var $this = this;

        setInterval(() => {
            checkRefreshToken();
          }, 1000)

        if(!sessionStorage.getItem('token') || (sessionStorage.getItem('token') && sessionStorage.getItem('token') === '')){
            getToken();
        }

        setInterval(() => {
            getQueueList(this.queuelist, function(id, data){
                $this.setState({
                    queues: data
                });
            })
        }, 5000);
    }

    render(){
        return(
            <React.Fragment>
                <Header />
                <div className='container-fluid' style={{ padding: '0' }}>
                    {this.state.queues.map(queue => {
                        var waitingQueueMax = this.waitingQueueMax(queue);

                        sessionStorage.setItem('servicelevel-' + queue.id, queue.serviceLevelPercentageToday);
                        sessionStorage.setItem('waitingqueue' + queue.id, queue.waitingConversations);
                        sessionStorage.setItem('requeued' + queue.id, queue.requeuedConversationsToday);
                        sessionStorage.setItem('longestwaiting' + queue.id, waitingQueueMax);
                        sessionStorage.setItem('offered' + queue.id, queue.offeredConversationsToday);
                        sessionStorage.setItem('handled' + queue.id, queue.answeredConversationsToday);
                        sessionStorage.setItem('abandoned' + queue.id, queue.abandonedConversationsToday);
                        sessionStorage.setItem('available' + queue.id, queue.agentsAvailable);
                        sessionStorage.setItem('idle' + queue.id, queue.agentsIdle);

                        return(
                            <React.Fragment>
                                <div className='queue'>
                                    <span className='queueName'>{queue ? queue.name : ''}</span>
                                    <div className="row">
                                        <WallboardBlock data={queue ? queue.serviceLevelPercentageToday : sessionStorage.getItem('servicelevel' + queue.id)} label="Service Level" />
                                        <WallboardBlock data={queue ? queue.waitingConversations : sessionStorage.getItem('waitingqueue' + queue.id)} label="Contacts waiting" />
                                        <WallboardBlock data={queue ? queue.requeuedConversationsToday : sessionStorage.getItem('requeued' + queue.id)} label="Requeued" />
                                        <WallboardBlock data={waitingQueueMax ? waitingQueueMax : sessionStorage.getItem('longestwaiting' + queue.id)} label="Longest waiting" />
                                        <WallboardBlock data={queue ? queue.offeredConversationsToday : sessionStorage.getItem('offered' + queue.id)} label="Offered" />
                                        <WallboardBlock data={queue ? queue.answeredConversationsToday : sessionStorage.getItem('handled' + queue.id)} label="Handled" />
                                        <WallboardBlock data={queue ? queue.abandonedConversationsToday : sessionStorage.getItem('abandoned' + queue.id)} label="Abandoned" />
                                        <WallboardBlock data={queue ? queue.agentsAvailable : sessionStorage.getItem('available' + queue.id)} label="Available" />
                                        <WallboardBlock data={queue ? queue.agentsIdle : sessionStorage.getItem('idle' + queue.id)} label="Idle" />
                                    </div>
                                </div>
                                
                            </React.Fragment>
                        )
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default List;