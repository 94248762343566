import Config from "./Config.json";

export const getCurrentDate = () => {
    const today = new Date();

    if((today.getMonth() + 1) < 10){
        var currentMonth = "0" + (today.getMonth() + 1)
    }else{
        currentMonth = today.getMonth();
    }

    if(today.getDate() < 10){
        var currentDay = "0" + today.getDate();
    }else{
        currentDay = today.getDate();
    }

    if(today.getHours() < 10){
        var currentHour = "0" + today.getHours();
    }else{
        currentHour = today.getHours();
    }

    if(today.getMinutes() < 10){
        var currentMinutes = "0" + today.getMinutes();
    }else{
        currentMinutes = today.getMinutes();
    }

    if(today.getSeconds() < 10){
        var currentSeconds = "0" + today.getSeconds();
    }else{
        currentSeconds = today.getSeconds();
    }

    return currentDay + '-' + currentMonth + '-' + today.getFullYear() + ' ' + currentHour + ':' + currentMinutes + ':' + currentSeconds;
  };



export const formatDate = (date, addHours) => {
    if(date){
        Date.prototype.addHours = function(h) {
            this.setTime(this.getTime() + (h*60*60*1000));
            return this;
        }
    
        if(addHours){
            date = new Date(date).addHours(1);
        }
    
        if((date.getMonth() + 1) < 10){
            var currentMonth = "0" + (date.getMonth() + 1)
        }else{
            currentMonth = (date.getMonth() + 1);
        }
    
        if(date.getDate() < 10){
            var currentDay = "0" + date.getDate();
        }else{
            currentDay = date.getDate();
        }
    
        if(date.getHours() < 10){
            var currentHour = "0" + date.getHours();
        }else{
            currentHour = date.getHours();
        }
    
        if(date.getMinutes() < 10){
            var currentMinutes = "0" + date.getMinutes();
        }else{
            currentMinutes = date.getMinutes();
        }
    
        if(date.getSeconds() < 10){
            var currentSeconds = "0" + date.getSeconds();
        }else{
            currentSeconds = date.getSeconds();
        }
        return date.getFullYear() + '-' + currentMonth + '-' + currentDay + ' ' + currentHour + ':' + currentMinutes + ':' + currentSeconds;
    }
}

export const getToken = () => {
    var details = Config.API_AUTH_CRED;
    
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Config.API_AUTH_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    })
    .then(response => response.json())
    .then(result => {
        var now = formatDate(new Date());
        sessionStorage.setItem('tokenDate', now);

        var expireDate = formatDate(new Date(), 1);
        sessionStorage.setItem('tokenExpire', expireDate);
        sessionStorage.setItem('token', result.access_token);
    })
};


export const checkRefreshToken = () => {
    var currentTime = new Date().getTime();
    var expireTime = new Date(sessionStorage.getItem('tokenExpire')).getTime();

    if(sessionStorage.getItem('tokenDate') && sessionStorage.getItem('tokenExpire')){
        if(currentTime > expireTime){
            sessionStorage.removeItem('token');
            window.location.reload();
        }
    }
}


export const dataSD = (agentgroupid) => {
    var SDData = [];
    
    fetch(Config.API_URL + "/queues/groups/" + agentgroupid + "/state", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        SDData.push(data);
    })
    
    return SDData;
}

export const getQueueList = (queuelist, callback) => {
    var queues = [];

    queuelist.forEach(queue => {
        fetch(Config.API_URL + "/queues/groups/" + queue + "/state", {
            method: 'GET',
            headers: { 
                "Authorization": "Bearer " + sessionStorage.getItem('token'),
                "Content-Type" : "application/json", 
            }
        })
        .then(response => response.json())
        .then(data => {
            queues.push(data);

            var sorted = queues.sort(function(a, b) {
                return queuelist.indexOf(a.id) - queuelist.indexOf(b.id);
            });
            
            return callback(queue, sorted);
        })
    })
    
}

export const getSDMembers = (membersgroupid, callback) =>{
    fetch(Config.API_URL + "/agents/groups/" + membersgroupid + "/members", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        var items = data._embedded.items
        return callback(membersgroupid, items);
    })
}

export const getAgentStates = (agentgroupid, callback) => {
    fetch(Config.API_URL + "/agents/states", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        var items = data._embedded.items;
        return callback(agentgroupid, items);
    })
}

export const getAgentConversations = () => {
    return fetch(Config.API_URL + "/conversations", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        var items = data._embedded.items
        return items;
    })
}